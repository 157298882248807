.wrapper {
  display: grid;
  grid-template-columns: 3fr 2fr 1fr;
  grid-gap: 0px;
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
}

.formWrapper {
  margin: auto 0;
  height: 100vh;
}

.form {
  display: flex;
  align-items: center;
  width: 65%;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .wrapper {
    grid-template-columns: auto;
    grid-template-rows: 1fr 1fr 1fr;
    height: 150vh;
  }

  .formWrapper { height: auto; }

  .form {
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 480px) {
  /*.wrapper { height: 117vh; }*/
  .wrapper { height: 102vh; }
  .formWrapper { padding-top: 30px; }
}

@media screen and (max-height: 575.98px) and (orientation:landscape) {
  .wrapper { height: 200vh; }
  .formWrapper { height: 200vh; }
}
